<template>
  <div>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp"
               app
               color="blue-grey darken-4"
               dark
               elevation="5"
               height="80">
      <v-app-bar-nav-icon @click="drawer = !drawer" />

      <div class="d-flex align-center">
        <v-img alt="ESS Logo"
               class="shrink mr-2"
               contain
               src="@/assets/logo-42x42.png"
               transition="scale-transition"
               width="40"
               @dblclick="$router.push('/logout')" />

        <v-toolbar-title class="ml-0 pl-1">
          <div>
            <div>
              <span class="font-weight-bold ess-font-text-h1">{{ appTitle }}</span>
            </div>
            <div class="font-weight-bold text-body-1">
              {{ authUser.isAdmin ? 'Admin' : authUser.role }} Console
            </div>
          </div>
        </v-toolbar-title>
      </div>

      <portal-target name="toolbar"
                     :class="{'ml-10': !isMobile, 'ml-5': isMobile}" />

      <v-spacer />

      <v-offline @detected-condition="amIOnline" />

      <v-tooltip left
                 v-if="offline">
        <template #activator="{on}">
          <v-chip color="error"
                  v-on="on"
                  label>
            Offline
          </v-chip>
        </template>
        <span>You are currently offline, however you are still able to use<br>
          the application to its full capability. All changes will be sync'ed <br>
          as soon as you go back online</span>
      </v-tooltip>

      <v-scroll-x-reverse-transition hide-on-leave>
        <ess-loader v-if="authUser.role === USER_ROLES.COACH && $wait.is('profileLoading')"
                    :size="30"
                    :width="4"
                    span />
      </v-scroll-x-reverse-transition>
      <v-scroll-x-reverse-transition hide-on-leave>
        <v-btn v-if="authUser.role === USER_ROLES.COACH && !$wait.is('profileLoading')"
               class="hidden-xs-only"
               text
               large
               @click="profilePanel = !profilePanel">
          <span>{{ activeProfile.sport }} - {{ activeProfile.name }}</span>
          <v-icon class="ml-3">
            mdi-card-account-details
          </v-icon>
        </v-btn>
      </v-scroll-x-reverse-transition>
      <v-scroll-x-reverse-transition hide-on-leave>
        <v-btn v-if="authUser.role === USER_ROLES.COACH && !$wait.is('profileLoading')"
               icon
               class="hidden-sm-and-up"
               @click="profilePanel = !profilePanel">
          <v-icon>mdi-card-account-details</v-icon>
        </v-btn>
      </v-scroll-x-reverse-transition>
    </v-app-bar>

    <ess-main-drawer :is-admin="isAdmin"
                     :menu-items="menuItems" />

    <ess-profile-drawer v-if="authUser.role === USER_ROLES.COACH" />
  </div>
</template>

<script>
import VOffline from 'v-offline';
import { mapGetters } from 'vuex';

import { USER_ROLES } from '@/config/constants';

export default {
  components: {
    EssMainDrawer: () => import('./EssMainDrawer'),
    EssProfileDrawer: () => import('./EssProfileDrawer'),
    VOffline,
  },

  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      USER_ROLES,
      offline: false,
    };
  },

  computed: {
    ...mapGetters({
      activeProfile: 'profiles/activeProfile',
    }),

    profilePanel: {
      get() {
        return this.$store.state.session.profilePanel;
      },
      set(value) {
        this.$store.commit('session/profilePanel', value);
      },
    },

    appTitle() {
      if (!this.isMobile) {
        return 'Elite Sport Science and Conditioning';
      }

      return 'ESS';
    },
    drawer: {
      get() {
        return this.$store.state.session.sidebar;
      },
      set(value) {
        this.$store.commit('session/sidebar', value);
      },
    },
  },

  methods: {
    amIOnline(e) {
      this.offline = !e;
    },
  },
};
</script>
